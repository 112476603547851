<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogTestVisible"
    title="新增数据"
    width="835px"
  >
    <el-form :model="form" size="small" label-width="95px">
      <el-form-item label="对方户名:">
        <div>
          <el-button @click="addOtherNameArr" type="primary" plain>
            新增
          </el-button>
          <p
            v-for="(item, index) in form.otherNameArr"
            :key="index"
            style="margin-top: 10px;"
          >
            <el-tag
              v-for="tag in item"
              :key="tag"
              :type="tag.match('!') ? 'danger' : 'primary'"
              style="margin-right: 5px;margin-bottom: 5px;"
              closable
              @close="handleCloseOtherName(tag, item)"
            >
              <span v-if="tag.match('!')">{{ tag.substring(1) }}</span>
              <span v-else>{{ tag }}</span>
            </el-tag>
            <el-input
              v-if="item.inputVisible"
              ref="InputRef"
              v-model="item.inputValue"
              @keyup.enter="handleInputOtherName(item, item.inputValue)"
              @blur="handleInputOtherName(item, item.inputValue)"
              style="width:120px"
            />
            <el-button v-else @click="showInputOtherName(item)">
              +添加
            </el-button>
          </p>
        </div>
      </el-form-item>
      <el-form-item label="摘要:">
        <div>
          <el-button @click="addBzArr" type="primary" plain>
            新增
          </el-button>
          <p
            v-for="(item, index) in form.bzArr"
            :key="index"
            style="margin-top: 10px;"
          >
            <el-tag
              v-for="tag in item"
              :key="tag"
              :type="tag.match('!') ? 'danger' : 'primary'"
              style="margin-right: 5px;margin-bottom: 5px;"
              closable
              @close="handleCloseBz(tag, item)"
            >
              <span v-if="tag.match('!')">{{ tag.substring(1) }}</span>
              <span v-else>{{ tag }}</span>
            </el-tag>
            <el-input
              v-if="item.inputVisible"
              ref="InputRef"
              v-model="item.inputValue"
              @keyup.enter="handleInputBz(item, item.inputValue)"
              @blur="handleInputBz(item, item.inputValue)"
              style="width:120px"
            />
            <el-button v-else @click="showInputBz(item)">
              +添加
            </el-button>
          </p>
        </div>
      </el-form-item>
      <el-form-item label="对方户名长度:">
        <el-input v-model.number="form.otherNameStr">
          <template #append>
            <el-radio-group v-model="form.otherNameType">
              <el-radio
                v-for="(item, index) in options"
                :value="item"
                :label="item"
              ></el-radio>
            </el-radio-group>
            <span class="cancel" @click="form.otherNameType = ''"
              >取消勾选</span
            >
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="收支类型:">
        <el-radio-group v-model="form.type">
          <el-radio :label="1">收入</el-radio>
          <el-radio :label="2">支出</el-radio>
        </el-radio-group>
        <span class="cancel" @click="form.type = 0">取消勾选</span>
      </el-form-item>
      <el-form-item label="金额:">
        <el-input v-model.number="form.amountStr">
          <template #append>
            <el-radio-group v-model="form.amountType">
              <el-radio
                v-for="(item, index) in options"
                :value="item"
                :label="item"
              ></el-radio>
            </el-radio-group>
            <span class="cancel" @click="form.amountType = ''">取消勾选</span>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogTestVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="saveData"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogTestVisible: false,
      form: {
        otherNameArr: [],
        otherName: "",
        bzArr: [],
        bz: "",
        otherNameLen: "",
        otherNameType: "",
        otherNameStr: "",
        amount: "",
        amountType: "",
        amountStr: "",
        type: 0,
        id: 0,
        parentId: 0,
        children: null,
      },
      options: ["大于", "小于", "大于等于", "小于等于"],
    };
  },

  methods: {
    //测试数据
    init(id,newId) {
      this.resetForm();
      this.form.id = newId;
      if (id) {
        this.form.parentId = id;
        this.form.parent_id = id;
      }
      this.dialogTestVisible = true;
    },
    resetForm() {
      this.form = {
        otherNameArr: [],
        otherName: "",
        bzArr: [],
        bz: "",
        otherNameLen: "",
        otherNameType: "",
        otherNameStr: "",
        amount: "",
        amountType: "",
        amountStr: "",
        type: 0,
        id: 0,
        parentId: 0,
        children: null,
      };
    },
    //新增对方户名
    addOtherNameArr() {
      this.form.otherNameArr.push([]);
    },
    handleCloseOtherName(tag, row) {
      row.splice(row.indexOf(tag), 1);
    },
    showInputOtherName(row) {
      row.inputVisible = true;
    },
    handleInputOtherName(row, value) {
      if (value) {
        this.$confirm("是否包含" + value + "？", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            row.push(value);
          })
          .catch(() => {
            row.push("!" + value);
          });
      }
      row.inputVisible = false;
      row.inputValue = "";
    },
    //新增摘要
    addBzArr() {
      this.form.bzArr.push([]);
    },
    handleCloseBz(tag, row) {
      row.splice(row.indexOf(tag), 1);
    },
    showInputBz(row) {
      row.inputVisible = true;
    },
    handleInputBz(row, value) {
      if (value) {
        this.$confirm("是否包含" + value + "？", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            row.push(value);
          })
          .catch(() => {
            row.push("!" + value);
          });
      }
      row.inputVisible = false;
      row.inputValue = "";
    },
    saveData() {
      if (isNaN(Number(this.form.amountStr))) {
        this.$qzfMessage("请输入正确的金额", 1);
        return;
      }
      //保存时处理对方户名
      let orName = "";
      this.form.otherNameArr.map((v) => {
        orName += v.join("||") + "&&";
      });
      this.form.otherName = orName.substring(0, orName.length - 2);
      //处理摘要
      let summary = "";
      this.form.bzArr.map((v) => {
        summary += v.join("||") + "&&";
      });
      this.form.bz = summary.substring(0, summary.length - 2);
      //处理对方户名长度
      this.form.otherNameLen = this.form.otherNameType + this.form.otherNameStr;
      //处理金额
      this.form.amount = this.form.amountType + this.form.amountStr;
      this.$emit("submit", this.form);
      this.dialogTestVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.cancel {
  color: #17a2b8;
  cursor: pointer;
  font-size: 12px;
  margin-left: 20px;
}
.el-radio {
  margin-right: 20px;
}
</style>
