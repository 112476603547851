<template>
  <div class="app-container">
    <div style="margin-bottom:10px;position: relative;">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="测试数据" name="1">
          <el-form
            size="small"
            label-width="120px"
            class="form_style"
            :inline="true"
          >
            <el-form-item label="对方户名">
              <el-input
                style="width:140px"
                v-model="testForm.otherName"
                placeholder="请输入对方户名"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="摘要">
              <el-input
                style="width:140px"
                v-model="testForm.summary"
                placeholder="请输入摘要"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="收支类型">
              <el-radio-group v-model="testForm.type">
                <el-radio :label="1">收入</el-radio>
                <el-radio :label="2">支出</el-radio>
              </el-radio-group>
              <span class="cancel" @click="testForm.type = 0">取消勾选</span>
            </el-form-item>
            <el-form-item label="金额">
              <el-input
                style="width:140px"
                v-model="testForm.amount"
                placeholder="请输入金额"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-button
                type="primary"
                @click="testData()"
                plain
                :loading="btnLoading"
                icon="Document"
                >测试</el-button
              >
            </el-form-item>
            <div v-if="showStatus" style="margin-left: 55px;">
              测试结果：&nbsp;&nbsp;<span v-if="resultOtherName"
                >对方户名：<span style="color:#17a2b8">{{
                  resultOtherName
                }}</span
                >、</span
              >
              &nbsp;&nbsp;<span v-if="resultSummary"
                >业务类型：<span style="color:#17a2b8">{{ resultSummary }}</span
                >、</span
              >
              &nbsp;&nbsp;<span v-if="resultCode"
                >科目：<span style="color:#17a2b8">{{ resultCode }}</span></span
              >
            </div>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <div style="position: absolute;top:3px;right:35px">
        <el-button type="primary" @click="add()" size="small" icon="Plus"
          >新增</el-button
        >
      </div>
    </div>
    <div class="bank_header">
      <div class="width_26">对方户名</div>
      <div class="width_8">对方户名长度</div>
      <div class="width_15">摘要</div>
      <div class="width_8">收支类型</div>
      <div class="width_8">金额</div>
      <div class="width_10">业务类型</div>
      <div class="width_10">科目</div>
      <div class="width_10">对方户名</div>
      <div class="width_5">操作</div>
    </div>
    <el-tree
      ref="tree"
      :style="{ height: contentStyleObj }"
      class="elTree"
      highlight-current
      :data="list"
      node-key="id"
      style="width:100%;overflow:scroll;"
      v-loading="loading"
      :default-expand-all="true"
      :expand-on-click-node="false"
      draggable
      :allow-drop="allowDrop"
      :allow-drag="allowDrag"
      @node-drag-start="handleDragStart"
      @node-drag-enter="handleDragEnter"
      @node-drag-leave="handleDragLeave"
      @node-drag-over="handleDragOver"
      @node-drag-end="handleDragEnd"
      @node-drop="handleDrop"
      :indent="8"
    >
      <template #default="{ node, data }">
        <div>
          <section class="bank-table-header">
            <div class="other_name width_26">
              <div class="ccyc name_left">
                <el-tooltip :disabled="!data.otherName" placement="top-start">
                  <template #content>
                    <div style="max-width: 500px;">
                      {{ filterText(data.otherName, "otherName") }}
                    </div>
                  </template>
                  <p v-for="(item, index) in data.otherNameArr" :key="index">
                    <el-tag
                      v-for="tag in item"
                      :key="tag"
                      :type="tag.match('!') ? 'danger' : 'primary'"
                      style="margin-right: 5px;"
                    >
                      <span v-if="tag.match('!')">{{ tag.substring(1) }}</span>
                      <span v-else>{{ tag }}</span>
                    </el-tag>
                  </p>
                </el-tooltip>
              </div>
              <div>
                <el-button
                  size="small"
                  link
                  @click="editName(data)"
                  :disabled="!data.id"
                  >编辑</el-button
                >
              </div>
            </div>
            <div class="other_name width_8">
              <div class="ccyc name_left">
                {{ data.otherNameLen }}
              </div>
              <div>
                <el-button
                  size="small"
                  link
                  @click="editLen(data)"
                  :disabled="!data.id"
                  >编辑</el-button
                >
              </div>
            </div>
            <div class="other_name width_15">
              <div class="ccyc name_left">
                <el-tooltip :disabled="!data.bz" placement="top-start">
                  <template #content>
                    <div style="max-width: 500px;">
                      {{ filterText(data.bz, "bz") }}
                    </div>
                  </template>
                  <p v-for="(item, index) in data.bzArr" :key="index">
                    <el-tag
                      v-for="tag in item"
                      :key="tag"
                      :type="tag.match('!') ? 'danger' : 'primary'"
                      style="margin-right: 5px;"
                    >
                      <span v-if="tag.match('!')">{{ tag.substring(1) }}</span>
                      <span v-else>{{ tag }}</span>
                    </el-tag>
                  </p>
                </el-tooltip>
              </div>
              <div>
                <el-button
                  size="small"
                  link
                  @click="editBz(data)"
                  :disabled="!data.id"
                  >编辑</el-button
                >
              </div>
            </div>
            <div class="other_name width_8">
              <div class="ccyc name_left">
                <div v-if="data.type == 1">收入</div>
                <div v-if="data.type == 2">支出</div>
              </div>
              <div>
                <el-button
                  size="small"
                  link
                  @click="editType(data)"
                  :disabled="!data.id"
                  >编辑</el-button
                >
              </div>
            </div>
            <div class="other_name width_8">
              <div class="ccyc name_left">
                {{ data.amount }}
              </div>
              <div>
                <el-button
                  size="small"
                  link
                  @click="editAmount(data)"
                  :disabled="!data.id"
                  >编辑</el-button
                >
              </div>
            </div>
            <div class="width_10 center">
              <el-input
                size="small"
                v-model="data.resultSummary"
                class="input_size"
              ></el-input>
            </div>
            <div class="width_10">
              <el-input
                size="small"
                v-model="data.resultCode"
                class="input_size"
              ></el-input>
            </div>
            <div class="width_10">
              <el-input
                size="small"
                v-model="data.resultOtherName"
                class="input_size"
              ></el-input>
            </div>
            <div class="center width_5">
              <i class="iconfont icon-jiahao" @click="addChildren(data)"></i>
              <i
                class="iconfont icon-shanchu"
                @click="remove(node, data)"
              ></i>
            </div>
            <!--  @click="del(data, scope.$index, list)" -->
          </section>
        </div>
      </template>
    </el-tree>
    <div style="margin-top: 20px;text-align: right;">
      <el-button size="small" type="success" @click="saveAll"
        ><i class="iconfont icon-baocun"></i> 保存</el-button
      >
    </div>
  </div>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    title="编辑对方户名"
    width="835px"
  >
    <div>
      <el-button size="small" @click="addOtherNameArr" type="primary" plain>
        新增
      </el-button>
      <p
        v-for="(item, index) in form.otherNameArr"
        :key="index"
        style="margin-top: 10px;"
      >
        <el-tag
          v-for="tag in item"
          :key="tag"
          :type="tag.match('!') ? 'danger' : 'primary'"
          style="margin-right: 5px;margin-bottom: 5px;"
          closable
          @close="handleCloseOtherName(tag, item)"
        >
          <span v-if="tag.match('!')">{{ tag.substring(1) }}</span>
          <span v-else>{{ tag }}</span>
        </el-tag>
        <el-input
          v-if="item.inputVisible"
          ref="InputRef"
          v-model="item.inputValue"
          size="small"
          @keyup.enter="handleInputOtherName(item, item.inputValue)"
          @blur="handleInputOtherName(item, item.inputValue)"
          style="width:120px"
        />
        <el-button v-else size="small" @click="showInputOtherName(item)">
          +添加
        </el-button>
      </p>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="saveOtherName"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogBzVisible"
    title="编辑摘要"
    width="835px"
  >
    <div>
      <el-button size="small" @click="addBzArr" type="primary" plain>
        新增
      </el-button>
      <p
        v-for="(item, index) in form.bzArr"
        :key="index"
        style="margin-top: 10px;"
      >
        <el-tag
          v-for="tag in item"
          :key="tag"
          :type="tag.match('!') ? 'danger' : 'primary'"
          style="margin-right: 5px;margin-bottom: 5px;"
          closable
          @close="handleCloseBz(tag, item)"
        >
          <span v-if="tag.match('!')">{{ tag.substring(1) }}</span>
          <span v-else>{{ tag }}</span>
        </el-tag>
        <el-input
          v-if="item.inputVisible"
          ref="InputRef"
          v-model="item.inputValue"
          size="small"
          @keyup.enter="handleInputBz(item, item.inputValue)"
          @blur="handleInputBz(item, item.inputValue)"
          style="width:120px"
        />
        <el-button v-else size="small" @click="showInputBz(item)">
          +添加
        </el-button>
      </p>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogBzVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="saveBz">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogLenVisible"
    title="编辑对方户名长度"
    width="835px"
  >
    <el-form :model="form" size="small">
      <el-form-item label="类型:">
        <el-radio-group v-model="form.otherNameType">
          <el-radio
            v-for="(item, index) in options"
            :value="item"
            :label="item"
          ></el-radio>
        </el-radio-group>
        <span class="cancel" @click="form.otherNameType = ''">取消勾选</span>
      </el-form-item>
      <el-form-item label="长度:">
        <el-input
          v-model.number="form.otherNameStr"
          style="width:154px"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogLenVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="saveLen">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogAmountVisible"
    title="编辑金额"
    width="835px"
  >
    <el-form :model="form" size="small">
      <el-form-item label="类型:">
        <el-radio-group v-model="form.amountType">
          <el-radio
            v-for="(item, index) in options"
            :value="item"
            :label="item"
          ></el-radio>
        </el-radio-group>
        <span class="cancel" @click="form.amountType = ''">取消勾选</span>
      </el-form-item>
      <el-form-item label="金额:">
        <el-input v-model="form.amountStr" style="width:154px"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogAmountVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="saveAmount"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogTypeVisible"
    title="编辑收支类型"
    width="400px"
  >
    <el-radio-group v-model="form.type">
      <el-radio :label="1">收入</el-radio>
      <el-radio :label="2">支出</el-radio>
    </el-radio-group>
    <span class="cancel" @click="form.type = 0">取消勾选</span>

    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogTypeVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="saveType"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <bankTest ref="bankTest" @submit="addLen" />
</template>

<script>
import { autoBankList, autoBankSave, autoBankTest } from "@/api/automatic";
import bankTest from "./components/bankTest.vue";
function deepClone(obj) {
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === "object") {
          objClone[key] = deepClone(obj[key]);
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}
export default {
  name: "",
  components: {
    bankTest,
  },
  props: {},
  data() {
    return {
      list: [],
      contentStyleObj: {},
      form: {},
      dialogVisible: false,
      dialogBzVisible: false,
      options: ["大于", "小于", "大于等于", "小于等于"],
      dialogLenVisible: false,
      dialogAmountVisible: false,
      dialogTypeVisible: false,
      testForm: {
        otherName: "",
        summary: "",
        amount: "",
        type: 0,
      },
      lastId: 0,
      resultOtherName: "",
      resultSummary: "",
      resultCode: "",
      showStatus: false,
      btnLoading: false,
    };
  },
  mounted() {
    this.getList();
    this.contentStyleObj = this.$getHeight(250);
  },
  methods: {
    getList() {
      autoBankList({}).then((res) => {
        if (res.data.msg == "success") {
          this.lastId = res.data.data.lastId + 1;
          this.list = res.data.data.list ? res.data.data.list : [];
          this.changeStr(this.list);
        }
      });
    },
    changeStr(list) {
      for (let i = 0; i < list.length; i++) {
        //处理对方户名
        if (list[i].otherName.indexOf("&&") != -1) {
          let arr = list[i].otherName.split("&&");
          let newArr = [];
          arr.map((item) => {
            if (item.indexOf("||") != -1) {
              let a = item.split("||");
              let c = [];
              a.map((v) => {
                c.push(v);
              });
              newArr.push(c);
            } else {
              newArr.push([item]);
            }
          });
          list[i].otherNameArr = newArr;
        } else {
          let arr = list[i].otherName.split("||");
          let newArr = [];
          if (arr[0]) {
            newArr = [arr];
          }
          list[i].otherNameArr = newArr;
        }

        //处理摘要
        if (list[i].bz.indexOf("&&") != -1) {
          let arr = list[i].bz.split("&&");
          let newArr = [];
          arr.map((item) => {
            if (item.indexOf("||") != -1) {
              let a = item.split("||");
              let c = [];
              a.map((v) => {
                c.push(v);
              });
              newArr.push(c);
            } else {
              newArr.push([item]);
            }
          });
          list[i].bzArr = newArr;
        } else {
          let arr = list[i].bz.split("||");
          let newArr = [];
          if (arr[0]) {
            newArr = [arr];
          }
          list[i].bzArr = newArr;
        }
        //处理对方户名长度
        if (list[i].otherNameLen) {
          if (list[i].otherNameLen.match("等于")) {
            list[i].otherNameType = list[i].otherNameLen.substring(0, 4);
            list[i].otherNameStr = list[i].otherNameLen.substring(4);
          } else {
            list[i].otherNameType = list[i].otherNameLen.substring(0, 2);
            list[i].otherNameStr = list[i].otherNameLen.substring(2);
          }
        } else {
          list[i].otherNameType = "";
          list[i].otherNameStr = "";
        }
        //处理金额
        if (list[i].amount) {
          if (list[i].amount.match("等于")) {
            list[i].amountType = list[i].amount.substring(0, 4);
            list[i].amountStr = list[i].amount.substring(4);
          } else {
            list[i].amountType = list[i].amount.substring(0, 2);
            list[i].amountStr = list[i].amount.substring(2);
          }
        } else {
          list[i].amountType = "";
          list[i].amountStr = "";
        }
        if (list[i].children && list[i].children.length > 0) {
          this.changeStr(list[i].children);
        }
      }
    },
    //修改对方户名--start
    editName(row) {
      console.log(row);
      this.form = deepClone(row);
      this.dialogVisible = true;
    },
    showInputOtherName(row) {
      row.inputVisible = true;
    },
    handleInputOtherName(row, value) {
      if (value) {
        this.$confirm("是否包含" + value + "？", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            row.push(value);
          })
          .catch(() => {
            row.push("!" + value);
          });
        // }
      }
      row.inputVisible = false;
      row.inputValue = "";
    },
    handleCloseOtherName(tag, row) {
      row.splice(row.indexOf(tag), 1);
    },
    addOtherNameArr() {
      this.form.otherNameArr.push([]);
    },
    saveOtherName() {
      this.changeOtherName(this.list);
      this.dialogVisible = false;
    },
    changeOtherName(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == this.form.id) {
          list[i].otherNameArr = this.form.otherNameArr;
          let or = "";
          let not = "";
          let and = "";
          this.form.otherNameArr.map((v) => {
            or += v.join("||") + "&&";
          });
          list[i].otherName = or.substring(0, or.length - 2);
          break;
        }
        if (list[i].children && list[i].children.length > 0) {
          this.changeOtherName(list[i].children);
        }
      }
    },
    //修改对方户名--end
    //修改摘要--start
    editBz(row) {
      this.form = deepClone(row);
      this.dialogBzVisible = true;
    },
    showInputBz(row) {
      row.inputVisible = true;
    },
    handleInputBz(row, value) {
      if (value) {
        this.$confirm("是否包含" + value + "？", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            row.push(value);
          })
          .catch(() => {
            row.push("!" + value);
          });
      }
      row.inputVisible = false;
      row.inputValue = "";
    },
    handleCloseBz(tag, row) {
      row.splice(row.indexOf(tag), 1);
    },
    addBzArr() {
      this.form.bzArr.push([]);
    },
    saveBz() {
      this.changeBz(this.list);
      this.dialogBzVisible = false;
    },
    changeBz(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == this.form.id) {
          list[i].bzArr = this.form.bzArr;
          let or = "";
          let not = "";
          let and = "";
          this.form.bzArr.map((v) => {
            or += v.join("||") + "&&";
          });
          list[i].bz = or.substring(0, or.length - 2);
          break;
        }
        if (list[i].children && list[i].children.length > 0) {
          this.changeBz(list[i].children);
        }
      }
    },
    //修改摘要--end
    //编辑对方户名长度--start
    editLen(row) {
      this.form = deepClone(row);
      this.dialogLenVisible = true;
    },
    saveLen() {
      this.changeLen(this.list);
      this.dialogLenVisible = false;
    },
    changeLen(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == this.form.id) {
          list[i].otherNameLen =
            this.form.otherNameType + this.form.otherNameStr;
          list[i].otherNameType = this.form.otherNameType;
          list[i].otherNameStr = String(this.form.otherNameStr);
          break;
        }
        if (list[i].children && list[i].children.length > 0) {
          this.changeLen(list[i].children);
        }
      }
    },
    //编辑对方户名长度--end
    //编辑金额--start
    editAmount(row) {
      this.form = deepClone(row);
      this.dialogAmountVisible = true;
    },
    saveAmount() {
      if (isNaN(Number(this.form.amountStr))) {
        this.$qzfMessage("请输入正确的金额", 1);
        return;
      }
      this.changeAmount(this.list);
      this.dialogAmountVisible = false;
    },
    changeAmount(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == this.form.id) {
          list[i].amount = this.form.amountType + this.form.amountStr;
          list[i].amountType = this.form.amountType;
          list[i].amountStr = this.form.amountStr;
          break;
        }
        if (list[i].children && list[i].children.length > 0) {
          this.changeAmount(list[i].children);
        }
      }
    },
    //编辑金额--end
    //编辑收支类型--start
    editType(row) {
      this.form = deepClone(row);
      this.dialogTypeVisible = true;
    },
    saveType() {
      this.changeType(this.list);
      this.dialogTypeVisible = false;
    },
    changeType(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == this.form.id) {
          list[i].type = this.form.type;
          break;
        }
        if (list[i].children && list[i].children.length > 0) {
          this.changeType(list[i].children);
        }
      }
    },
    //编辑收支类型--end
    saveAll() {
      autoBankSave({ list: this.list }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.getList();
        }
      });
    },
    //新增一级
    add() {
      this.$refs.bankTest.init(0, this.lastId);
    },
    //新增一级
    addLen(form) {
      if (form.parentId) {
        this.checkId(this.list, form);
      } else {
        this.list.push(form);
        this.lastId = this.lastId + 1;
      }
    },
    checkId(list, form) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == form.parentId) {
          if (list[i].children) {
            list[i].children.push(form);
          } else {
            list[i].children = [];
            list[i].children.push(form);
          }
          this.lastId = this.lastId + 1;
          break;
        }
        if (list[i].children && list[i].children.length > 0) {
          this.checkId(list[i].children, form);
        }
      }
    },
    //新增下级
    addChildren(row) {
      this.$refs.bankTest.init(row.id, this.lastId);
    },
    //删除
    del(row, index, data) {
      if (row.parent_id == 0) {
        let firstIndex = data.findIndex((item) => item.id === row.id);
        data.splice(firstIndex, 1);
      } else {
        let parentObject = this.findNodeIndexInTree(
          data,
          row.id,
          row.parent_id
        ); //父节点对象
        if (parentObject) {
          let index = parentObject.children.findIndex(
            (item) => item.id === row.id
          ); //在父节点中获取子节点Index
          parentObject.children.splice(index, 1);
          if (parentObject.children.length == 0) {
            parentObject.children = null;
          }
        }
      }
    },
    findNodeIndexInTree(tree, targetA, targetParentId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.id == targetParentId) {
          return node;
        }
        if (Array.isArray(node.children)) {
          const childIndex = this.findNodeIndexInTree(
            node.children,
            targetA,
            targetParentId
          );
          if (childIndex !== null) {
            return childIndex;
          }
        }
      }
      return null; // Node not found
    },
    // 样式
    tableRowClassName({ row, rowIndex }) {
      let status = false;
      if (!row.parent_id) {
        status = true;
      }
      if (status) {
        return "first-row";
      }
      return "";
    },
    //测试数据
    testData() {
      if (
        !this.testForm.summary &&
        !this.testForm.otherName &&
        !this.testForm.amount &&
        !this.testForm.type
      ) {
        this.$qzfMessage("至少输入一条", 1);
        return;
      }
      this.btnLoading = true;
      this.testForm.amount = Number(this.testForm.amount);
      autoBankTest(this.testForm).then((res) => {
        this.btnLoading = false;
        if (res.data.msg == "success") {
          this.showStatus = true;
          this.resultOtherName = res.data.data.otherName;
          this.resultSummary = res.data.data.summary;
          this.resultCode = res.data.data.code;
        }
      });
    },
    handleChange(e) {
      this.activeNames = e;
      this.$nextTick(() => {
        this.contentStyleObj =
          e.length == 0 ? this.$getHeight(250) : this.$getHeight(300);
      });
    },
    remove(node,data){
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex((d) => d.id === data.id)
      children.splice(index, 1)
      this.list = [...this.list]
    },
    //处理渲染
    filterText(text, type) {
      let str = "";
      if (text) {
        str = text.replace(/\|\|!/g, "不包括");
        str = str.replace(/\&\&!/g, "并且不包括");
        str = str.replace(/\&\&/g, "并且包括");
        str = str.replace(/\|\|/g, "包括");
        str = str.replace(/!/g, "不包括");
        if (text.indexOf("!") != 0) {
          str = type == "otherName" ? "对方户名包括" + str : "摘要包括" + str;
        } else {
          str = type == "otherName" ? "对方户名" + str : "摘要" + str;
        }
      }
      return str;
    },
    //节点开始拖拽时
    handleDragStart(node,ev){

    },
    //拖拽进入其他节点时
    handleDragEnter(draggingNode, dropNode, ev) {
      // console.log(draggingNode, dropNode, ev);
    },
    //拖拽离开某个节点时
    handleDragLeave(draggingNode, dropNode, ev) {
      // console.log(draggingNode, dropNode, ev);
    },
    //在拖拽节点时
    handleDragOver(draggingNode, dropNode, ev) {
      // console.log(draggingNode, dropNode, ev);
    },
    //拖拽结束时
    handleDragEnd(draggingNode, dropNode,dropType, ev) {
      // console.log(draggingNode, dropNode, ev);
    },
    //拖拽成功时
    handleDrop(draggingNode, dropNode, dropType, ev) {
      // console.log(draggingNode, dropNode, dropType, ev);
      // console.log(this.list);
    },
    //允许拖拽
    allowDrag(node){
      return true
    },
    //拖拽行为
    allowDrop(draggingNode,dropNode,type){
      return true
    }
  },
};
</script>

<style scoped lang="scss">
.flex_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cancel {
  color: #17a2b8;
  cursor: pointer;
  font-size: 12px;
  margin-left: 40px;
}
:deep(.el-collapse-item__content) {
  padding-bottom: 0;
}
:deep(.el-collapse-item__header) {
  height: 30px;
  line-height: 30px;
}
.form_style .el-form-item {
  margin-bottom: 5px;
}
.bank-table-header {
  border: 1px solid #b9b9b9;
  display: flex;
  overflow: hidden;
  font-size: 13px;
  margin: 5px 0;
  > div {
    border-right: 1px solid #b9b9b9;
    line-height: 24px;
    padding: 0 2px;
    // flex: 1;
  }
  > div:last-child {
    border-right: 0;
  }
}
.bank_header {
  width: calc(100% - 41px);
  margin-left: 24px;
  display: flex;
  font-size: 13px;
  background: var(--themeColor, #17a2b8);
  color: #fff;
  height: 30px;
  line-height: 30px;
  > div {
    text-align: center;
    border-right: 1px solid #b9b9b9;
  }
  > div:last-child {
    border-right: 0;
  }
}
:deep(.el-tree-node__content) {
  width: 100%;
  height: auto;
}
:deep(.el-tree-node__label) {
  width: 100%;
}
.icon-jiahao {
  color: #17a2b8;
}
.icon-shanchu {
  color: red;
  margin-left: 8px;
}
.ccyc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #212529;
}
.other_name {
  display: flex;
  justify-content: space-between;
}
.center {
  text-align: center;
}
.width_10 {
  width: 10%;
}
.width_8 {
  width: 8%;
}
.name_left {
  width: 90%;
}
.width_5 {
  width: 5%;
}
.width_15 {
  width: 15%;
}
.width_26 {
  width: 26%;
}
.input_size {
  width: 95%;
}

:deep(.el-tree-node__content) {
  width: 100%;
  height: auto;
  &:hover {
    background-color: #e4f2ff !important;
  }
  &:focus {
    background-color: #e4f2ff !important;
  }
}

</style>
<style>
.el-table .first-row {
  background: #ecf7f9;
}
</style>
